/**
// Privacy Policy page component using React and Material UI
 * 
 * This component renders the privacy policy page for the YallaFalla application.
 * It uses Material UI for styling and React for rendering.
 * 
 * @component
 * @example
 * return (
 *   <PrivacyPolicy />
 * )
 */
import { useNavigate } from "react-router-dom";
import { format } from 'date-fns';
import { Container, Box, Typography, IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTranslation } from "react-i18next";
import IntroductionPageLanguageSwitcher from "../components/IntroductionPageLanguageSwitcher";

function PrivacyPolicy() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentLanguage = localStorage?.getItem("language");
  const goBack = () => {
    navigate(-1);
  };

  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Typography
          gutterBottom
          alignSelf={"start"}
          sx={{
            fontSize: "24px",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton aria-label="back" onClick={goBack}>
            {currentLanguage === "ar" ? (
              <ChevronRightIcon
                sx={{
                  fontSize: "35px",
                  color: "black",
                  cursor: "pointer",
                }}
              />
            ) : (
              <ChevronLeftIcon
                sx={{
                  fontSize: "35px",
                  color: "black",
                  cursor: "pointer",
                }}
              />
            )}
          </IconButton>
          {t('privacyPolicyTitle')}
        </Typography>
        <Box>
          <IntroductionPageLanguageSwitcher />
        </Box>
      </Box>
      <Box>
        <p>{t('lastUpdated')}: {format(new Date(2024, 11, 13), 'dd-MM-yyyy')}</p>
        <h2>{t('introductionTitle')}</h2>

        <p>{t('introductionContent')}</p>

        <hr />

        <h2>{t('informationWeCollectTitle')}</h2>

        <h3>{t('personalInformationTitle')}</h3>
        <p>{t('personalInformationContent')}</p>

        <h3>{t('technicalAndUsageInformationTitle')}</h3>
        <p><strong>{t('logDataContent')}</strong></p>
        <p><strong>{t('usageStatisticsContent')}</strong></p>

        <hr />

        <h3>{t('howWeUseYourInformationTitle')}</h3>
        <p>{t('howWeUseYourInformationContent')}</p>
        <p>{t('communicationContent')}</p>
        <p>{t('securityAndFraudPreventionContent')}</p>

        <hr />
        <h3>{t('sharingYourInformationTitle')}</h3>
        <p>{t('serviceProvidersContent')}</p>
        <p>{t('legalRequirementsContent')}</p>
        <p>{t('businessTransfersContent')}</p>

        <hr />

        <h3>{t('yourRightsTitle')}</h3>
        <p>{t('accessAndUpdateContent')}</p>
        <p>{t('deletionContent')}</p>
        <p>{t('optOutContent')}</p>

        <hr />

        <h3>{t('dataSecurityTitle')}</h3>
        <p>{t('dataSecurityContent')}</p>

        <hr />

        <h3>{t('changesToThisPolicyTitle')}</h3>
        <p>{t('changesToThisPolicyContent')}</p>

        <hr />

        <h3>{t('contactUsTitle')}</h3>
        <p>{t('contactUsContent')}</p>
        <p>{t('email')}: <a href="mailto:sales@yallafalla.com">sales@yallafalla.com</a></p>
        <p>{t('phone')}: <a href="tel:+966566994699">+966 56 699 4699</a></p>
      </Box>
    </Container>
  )
}

export default PrivacyPolicy;
