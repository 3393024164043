import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  Link,
  Alert,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import { signup } from "../services/api";
import EmailIcon from "@mui/icons-material/Email";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';



const RegistrationPage = () => {
  const { t } = useTranslation();
  const currentLanguage = localStorage.getItem("language");

  const [email, setEmail] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();

  const goBackHome = () => {
    navigate("/");
  };

  const validate = () => {
    if (!/\S+@\S+\.\S+/.test(email)) {
      setError(t("invalidEmailError"));
      return false;
    }
    if (!validatePrivacyPolicyAcceptance()) {
      setError(t("policyAcceptanceError"));
      return false;
    }

    setError("");
    return true;
  };

  const validatePrivacyPolicyAcceptance = () => {
    return isChecked;
  };

  const handleSendOTP = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError("");
    setSuccess("");
    let valid = validate();
    if (!valid) {
      setLoading(false);
      return;
    }
    try {
      await signup(first_name, last_name, email, "Riyadh");
      setSuccess("OTP sent successfully.");
      navigate("/verify-otp", { state: { email } });
    } catch (error) {
      setError("Failed to send OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="xs">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="80vh"
      >
        <Typography
          variant="h5"
          gutterBottom
          alignSelf={"start"}
          sx={{
            position: "absolute",
            top: "30px", // Adjust the vertical distance from the top
            left: currentLanguage === "en" ? "16px" : "0px", // Adjust the horizontal distance from the right
            right: currentLanguage === "ar" ? "16px" : "0px",
          }}
        >
          <IconButton aria-label="back" onClick={goBackHome}>
            {currentLanguage === "en" ? (
              <ChevronLeftIcon></ChevronLeftIcon>
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
          {t("Registration")}
        </Typography>
        <Box sx={{ width: "100%" }}>
          {error && <Alert severity="error">{error}</Alert>}
          {success && <Alert severity="success">{success}</Alert>}
        </Box>
        <Box component="form" width="100%" mt={2} onSubmit={handleSendOTP}>
          {/* first name */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
              width: "100%",
              padding: "10px 0px",
              fontSize: "16px",
            }}
          >
            <label
              style={{
                fontSize: "18px",
                fontWeight: "semibold",
                marginLeft: "20px",
                marginBottom: "-10px",
              }}
            >
              {t("FirstName")}
            </label>
            <TextField
              placeholder={t("FirstName")}
              value={first_name}
              onChange={(e) => setFirstName(e.target.value)}
              fullWidth
              margin="normal"
              required
              InputProps={{
                sx: {
                  borderRadius: "50px",
                },
              }}
            />
          </Box>
          {/* last name */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
              width: "100%",
              padding: "10px 0px",
              fontSize: "16px",
            }}
          >
            <label
              style={{
                fontSize: "18px",
                fontWeight: "semibold",
                marginLeft: "20px",
                marginBottom: "-10px",
              }}
            >
              {t("LastName")}
            </label>
            <TextField
              placeholder={t("LastName")}
              value={last_name}
              onChange={(e) => setLastName(e.target.value)}
              fullWidth
              margin="normal"
              required
              InputProps={{
                sx: {
                  borderRadius: "50px",
                },
              }}
            />
          </Box>

          {/* email */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
              width: "100%",
              padding: "10px 0px",
              fontSize: "16px",
            }}
          >
            <label
              style={{
                fontSize: "18px",
                fontWeight: "semibold",
                marginLeft: "20px",
                marginBottom: "-10px",
              }}
            >
              {t("Email")}
            </label>
            <TextField
              placeholder={t("EnterYourEmail")}
              fullWidth
              required
              value={email} // Using email state
              onChange={(event) => setEmail(event.target.value)}
              type="email"
              margin="normal"
              InputProps={{
                sx: {
                  borderRadius: "50px",
                },
                startAdornment: (
                  <InputAdornment>
                    <EmailIcon
                      sx={{ paddingRight: "10px", color: "#F26557" }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box>
            <FormGroup>
              <FormControlLabel required control={
                <Switch
                  checked={isChecked}
                  onChange={(event) => setIsChecked(event.target.checked)}
                />
              }
                label={
                  <Link
                    href="/privacy-policy"
                    style={{
                      color: "#F26557",
                      textDecoration: "none",
                    }}
                  >
                    {t("PrivacyAndUsagePolicy")}
                  </Link>
                } />
            </FormGroup>
          </Box>
          <LoadingButton
            sx={{
              backgroundColor: "#F26456",
              borderRadius: "50px",
              marginY: "20px",
            }}
            fullWidth
            size="large"
            onClick={handleSendOTP}
            loading={loading}
            variant="contained"
            loadingPosition="start"
            startIcon={
              <SmsOutlinedIcon
                sx={{
                  paddingX: "5px",
                }}
              />
            }
          >
            <span>{t("SendOtp")}</span>
          </LoadingButton>
          <Typography variant="body2" align="center">
            {t("AlreadyHaveAccount")}{" "}
            <Link
              style={{
                color: "#F07063",
                textDecoration: "none",
              }}
              href="/login"
              color="secondary"
            >
              {t("Login")}
            </Link>
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default RegistrationPage;
