import React from "react";
import { Link } from "react-router-dom";

import classes from "./Header.module.css";

import logo from "../assets/logo 06-2 1.png";

function Header() {
  return (
    <header className={classes.header} dir="rtl">
      <img src={logo} alt="" />
      <nav>
        <Link to={"/"}>الرئيسية</Link>
        <Link to="/market">سوق الفعاليات </Link>
        <Link>من نحن </Link>
        <Link>اتصل بنا</Link>
      </nav>
    </header>
  );
}

export default Header;
