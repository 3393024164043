import { Box, Container } from "@mui/material";
import React, { useEffect } from "react";
import styles from "./BookingRegistrationPage.module.css";
import MaleIcon from "@mui/icons-material/Male";
import EmailIcon from "@mui/icons-material/Email";
import BadgeIcon from "@mui/icons-material/Badge";
import { MuiTelInput } from "mui-tel-input";
import BookingPagesHeader from "../components/BookingPagesHeader";
import { useForm } from "react-hook-form";
import useFetchCountries from "../hooks/useFetchCountries";
import useFetchCities from "../hooks/useFetchCities";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { firstTimeBooking } from "../services/guest";
import { useTranslation } from "react-i18next";
import PersonIcon from "@mui/icons-material/Person";

function BookingRegistrationPage() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  const { t } = useTranslation();

  // Helper function to safely get localStorage item
  const getLocalStorageItem = (key) => {
    const item = localStorage.getItem(key);
    return item && item !== "null" && item !== "undefined" ? item : "";
  };

  // Retrieve data from localStorage with safer method
  const customerName = getLocalStorageItem("customer_name");
  const customerPhone = getLocalStorageItem("mobile_number");
  const customerCountry = getLocalStorageItem("country");
  const customerCity = getLocalStorageItem("city");
  const customerEmail = getLocalStorageItem("email");
  const customerNid = getLocalStorageItem("nid");
  const customerGender = getLocalStorageItem("gender");
  const customerAge = getLocalStorageItem("age");

  const { countries } = useFetchCountries();
  const { cities } = useFetchCities();

  const navigate = useNavigate();
  const param = useParams();
  const location = useLocation();
  const path = location.pathname;
  const currentPage = path.split("/").pop();

  // Effect to set initial values for form fields
  useEffect(() => {
    // Set initial values, using empty string if no value
    setValue("fullName", customerName);
    setValue("phone", customerPhone);
    setValue("country", customerCountry);
    setValue("city", customerCity);
    setValue("gender", customerGender);
    setValue("age", customerAge);
    setValue("id", customerNid);
  });

  const onSubmit = async (data) => {
    try {
      const nid = data.id;
      const city = data.city;
      const gender = data.gender;
      const age = data.age;
      const email = customerEmail;

      const response = await firstTimeBooking(nid, city, gender, age, email);
      navigate(`/${param.activityId}/booking/booking-information`, {
        state: data,
      });

      localStorage.setItem(
        "first_time_booking",
        response.data.first_time_booking
      );

      return response;
    } catch (error) {
      console.error("Error booking event:", error);
    }
  };

  return (
    <Box>
      <BookingPagesHeader currentPage={currentPage} />
      <Container
        sx={{
          marginY: "50px",
        }}
      >
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          {/* name */}
          <div className={styles.formGroup}>
            <label>
              <PersonIcon
                sx={{
                  color: "rgba(242,101,87,76%)",
                }}
              />
            </label>
            <input
              type="text"
              name="fullName"
              placeholder={t("FullName")}
              {...register("fullName", {
                required: {
                  value: true,
                  message: t("FullNameIsRequired"),
                },
              })}
            />
            {errors.fullName && <span>{errors.fullName.message}</span>}
          </div>

          {/* phone */}
          <div style={{ width: "95%" }}>
            <MuiTelInput
              value={watch("phone")}
              InputProps={{
                sx: {
                  borderRadius: "50px",
                  border: "0px",
                },
              }}
              defaultCountry="SA"
              fullWidth
              margin="normal"
              placeholder={t("PhoneNumber")}
              onChange={(value) =>
                setValue("phone", value, { shouldValidate: true })
              }
            />
            {errors.phone && <span>{errors.phone.message}</span>}
          </div>

          {/* country */}
          <div className={styles.formGroup}>
            <select
              name="country"
              value={watch("country")}
              {...register("country", {
                required: {
                  value: true,
                  message: t("CountryIsRequired"),
                },
              })}
              onChange={(e) => {
                setValue("country", e.target.value, { shouldValidate: true });
              }}
            >
              <option
                className={styles.firstOption}
                value=""
                disabled
                selected
                hidden
              >
                {t("Country")}
              </option>
              {countries.map((country) => (
                <option key={country.country_name} value={country.name}>
                  {country.country_name}
                </option>
              ))}
            </select>
            {errors.country && (
              <span className={styles.errorMessage}>
                {errors.country.message}
              </span>
            )}
          </div>

          {/* city and age */}
          <div className={styles.formRow}>
            <div className={styles.formGroup} style={{ flex: "0 0 60%" }}>
              <select
                name="city"
                {...register("city", {
                  required: {
                    value: true,
                    message: t("CityIsRequired"),
                  },
                })}
                onChange={(e) => {
                  setValue("city", e.target.value, { shouldValidate: true });
                }}
              >
                <option
                  className={styles.firstOption}
                  value=""
                  disabled
                  selected
                  hidden
                >
                  {t("City")}
                </option>
                {cities.map((city) => (
                  <option key={city.name} value={city.name}>
                    {city.city_name}
                  </option>
                ))}
              </select>
              {errors.city && (
                <span className={styles.errorMessage}>
                  {errors.city.message}
                </span>
              )}
            </div>
            <div className={styles.formGroup} style={{ flex: "0 0 30%" }}>
              <input
                type="number"
                name="age"
                placeholder={t("Age")}
                {...register("age", {
                  required: {
                    value: true,
                    message: t("AgeIsRequired"),
                  },
                })}
              />
              {errors.age && (
                <span className={styles.errorMessage}>
                  {errors.age.message}
                </span>
              )}
            </div>
          </div>

          {/* gender */}
          <div className={styles.formGroup}>
            <label htmlFor="gender">
              <MaleIcon
                sx={{
                  color: "rgba(242,101,87,76%)",
                }}
              />
            </label>
            <select
              name="gender"
              {...register("gender", {
                required: {
                  value: true,
                  message: t("GenderIsRequired"),
                },
              })}
            >
              <option
                className={styles.firstOption}
                value=""
                disabled
                selected
                hidden
              >
                {t("Gender")}
              </option>
              <option value="male">{t("Male")}</option>
              <option value="female">{t("Female")}</option>
              <option value="Prefer not to say">{t("PreferNotToSay")}</option>
            </select>
            {errors.gender && (
              <span className={styles.errorMessage}>
                {errors.gender.message}
              </span>
            )}
          </div>

          {/* id */}
          <div className={styles.formGroup}>
            <label>
              <BadgeIcon
                sx={{
                  color: "rgba(242,101,87,76%)",
                }}
              />
            </label>
            <input
              type="text"
              name="id"
              placeholder={t("NationalID")}
              {...register("id", { required: "ID is required" })}
            />
            {errors.id && (
              <span className={styles.errorMessage}>{errors.id.message}</span>
            )}
          </div>

          {/* email */}
          <div className={styles.formGroup}>
            <label>
              <EmailIcon
                sx={{
                  color: "rgba(242,101,87,76%)",
                }}
              />
            </label>
            <input
              disabled
              type="email"
              name="email"
              value={customerEmail}
              readOnly
              placeholder={t("Email")}
              {...register("email")}
              style={{
                backgroundColor: "#f0f0f0",
                color: "#a8a8a8",
                border: "1px solid #ccc",
                cursor: "not-allowed",
              }}
            />
            {errors.email && (
              <span className={styles.errorMessage}>
                {errors.email.message}
              </span>
            )}
          </div>

          {/* confirm email */}
          <div className={styles.formGroup}>
            <label htmlFor="emailConfirmation">
              <EmailIcon
                sx={{
                  color: "rgba(242,101,87,76%)",
                }}
              />
            </label>
            <input
              disabled
              type="email"
              name="emailConfirmation"
              value={customerEmail}
              readOnly
              placeholder={t("EmailConfirmation")}
              {...register("emailConfirmation")}
              style={{
                backgroundColor: "#f0f0f0",
                color: "#a8a8a8",
                border: "1px solid #ccc",
                cursor: "not-allowed",
              }}
            />
            {errors.emailConfirmation && (
              <span className={styles.errorMessage}>
                {errors.emailConfirmation.message}
              </span>
            )}
          </div>

          {/* next button */}
          <button type="submit" className={styles.submitButton}>
            {t("NextButtonText")}
          </button>
        </form>
      </Container>
    </Box>
  );
}

export default BookingRegistrationPage;
