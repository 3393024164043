import React, { useEffect, useRef, useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { receiveMessage, sendMessage } from "../../services/guest";
const ChattingPage = () => {
  const [messages, setMessages] = useState([]); // Stores chat messages
  const [newMessage, setNewMessage] = useState(""); // Message to be sent
  const [receiver] = useState("ahmed208087@gmail.com");
  const currentSender = localStorage.getItem("email");

  const { t } = useTranslation();
  const currentLanguage = localStorage.getItem("language");

  const navigate = useNavigate();
  const chatContainerRef = useRef(null); // Reference to chat container

  const goBackHome = () => {
    navigate(-1);
  };

  const fetchMessages = async () => {
    try {
      const userA = localStorage.getItem("email"); // Replace with the current user email
      const userB = receiver; // Replace with the receiver email
      const response = await receiveMessage(userA, userB);
      if (response) {
        setMessages(response); // Set fetched messages
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return; // Avoid sending empty messages
    try {
      await sendMessage(newMessage, receiver);
      setNewMessage(""); // Clear input
      fetchMessages(); // Refresh the chat
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  // Scroll to bottom whenever messages change
  //   useEffect(() => {
  //     if (chatContainerRef.current) {
  //       chatContainerRef.current.scrollTop =
  //         chatContainerRef.current.scrollHeight;
  //     }
  //   }, [messages]);

  useEffect(() => {
    fetchMessages();
    const interval = setInterval(fetchMessages, 5000); // Poll every 5 seconds
    return () => clearInterval(interval); // Clear interval on unmount
  });

  return (
    <div>
      {/* title and navigation */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alight: "center",
          borderBottom: "1px solid rgba(102, 112, 128, 0.18)",
        }}
      >
        <Box
          sx={{
            width: "100%",
            padding: "20px  0px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            gutterBottom
            alignSelf={"start"}
            sx={{
              fontSize: "24px",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton aria-label="back" onClick={goBackHome}>
              {currentLanguage === "en" ? (
                <ChevronLeftIcon
                  sx={{
                    fontSize: "35px",
                    color: "black",
                    cursor: "pointer",
                  }}
                />
              ) : (
                <ChevronRightIcon
                  sx={{
                    fontSize: "35px",
                    color: "black",
                    cursor: "pointer",
                  }}
                />
              )}
            </IconButton>
            {t("chat")}
          </Typography>
        </Box>
        <IconButton>
          <MoreVertIcon
            sx={{
              width: "24px",
              height: "24px",
              color: "black",
            }}
          />
        </IconButton>
      </Box>
      {/* text input */}
      <Box
        ref={chatContainerRef}
        sx={{
          padding: "20px",
          height: "70vh",
          overflowY: "auto",
          border: "1px solid rgba(102, 112, 128, 0.18)",
          marginBottom: "10px",
        }}
      >
        {messages.map((msg, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "10px",
              alignItems:
                msg.sender === currentSender ? "flex-end" : "flex-start",
            }}
          >
            <Typography
              sx={{
                backgroundColor:
                  msg.sender === currentSender ? "#F26456" : "#D3D2D2",
                color: msg.sender === currentSender ? "#fff" : "#000",
                padding: "10px 30px",
                borderRadius: "50px",
                display: "inline-block",
                textAlign: "start",
                maxWidth: "70%", // Optional: controls message bubble width
                wordWrap: "break-word", // Ensures text wraps within the bubble
              }}
            >
              {msg.content}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                color: "gray",
                display: "block",
                marginTop: "5px",
                textAlign: msg.sender === currentSender ? "right" : "left", //
              }}
            >
              {(() => {
                const creationDate = new Date(msg.creation);
                const today = new Date();
                const yesterday = new Date();
                yesterday.setDate(today.getDate() - 1);

                const isToday =
                  creationDate.toDateString() === today.toDateString();
                const isYesterday =
                  creationDate.toDateString() === yesterday.toDateString();

                if (isToday) {
                  return `Today ${creationDate.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}`;
                } else if (isYesterday) {
                  return `Yesterday ${creationDate.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}`;
                } else {
                  return `${creationDate.toLocaleDateString()} ${creationDate.toLocaleTimeString(
                    [],
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                    }
                  )}`;
                }
              })()}
            </Typography>
          </Box>
        ))}
      </Box>

      {/* Input and Send Button */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <TextField
          fullWidth
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder={t("Type a message...")}
          sx={{ marginRight: "10px" }}
        />
        <Button
          variant="contained"
          onClick={handleSendMessage}
          disabled={!newMessage.trim()}
          sx={{
            backgroundColor: "#F26456",
          }}
        >
          {t("Send")}
        </Button>
      </Box>
    </div>
  );
};

export default ChattingPage;
