import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import EventsPage from "./pages/EventsPage";
import SupportPage from "./pages/SupportPage";
import LoginPage from "./pages/LoginPage";
import RegistrationPage from "./pages/RegistrationPage";
import VerifyOtpPage from "./pages/VerifyOtpPage";
import SetPassword from "./pages/SetPassword";
import ExplorePage from "./pages/ExplorePage";
import ExploreCityPage from "./pages/ExploreCityPage";
import SingleActivityPage from "./pages/SingleActivityPage";
import BottomNavBar from "./components/BottomNavBar";
import OrderDetailPage from "./pages/OrderDetailPage";
import ReviewInterfacePage from "./pages/ReviewInterfacePage";
import OrderReviewPage from "./pages/OrderReviewPage";
import OrdersPage from "./pages/OrdersPage";
import OrdersPrevious from "./pages/OrdersPrevious";
import OrdersUpcoming from "./pages/OrdersUpcoming";
import BookingInformation from "./pages/BookingInformation";
import BookingRegistrationPage from "./pages/BookingRegistrationPage";
import BookingPaymentPage from "./pages/BookingPaymentPage";
import SingleActivityReOrderPage from "./pages/SingleActivityReOrderPage";
import SingleOrderCancelationPage from "./pages/SingleOrderCancelationPage";
import ProfilePage from "./pages/ProfilePage";
import { LanguageProvider } from "./services/LanguageContext";
import AllActivitiesPage from "./pages/AllActivitiesPage";
import HomePage from "./pages/HomePage";
import Introduction from "./pages/Introduction";
import MyFavouritePage from "./pages/MyFavouritePage";
import MyAccountPage from "./pages/MyAccountPage";
import { createTheme, ThemeProvider } from "@mui/material";
import "./App.css";
import ForgetPasswordEmailPage from "./pages/forget-password/ForgetPasswordEmailPage";
import ForgetPasswordOtpPage from "./pages/forget-password/ForgetPasswordOtpPage";
import QrCodePage from "./pages/QrCodePage";
import ChattingPage from "./pages/chat/ChattingPage";
import NotificationsPage from "./pages/chat/NotificationsPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import LandingPage from "./pages/LandingPage";

const theme = createTheme({
  typography: {
    // Apply the default fonts for the body
    fontFamily: "english-regular, sans-serif", // Default English font for most content
    h1: {
      fontFamily: "english-bold, sans-serif", // Use bold font for h1
    },
    h2: {
      fontFamily: "english-semi-bold, sans-serif", // Use semi-bold for h2
    },
    h3: {
      fontFamily: "english-medium, sans-serif", // Use medium weight for h3
    },
    h4: {
      fontFamily: "english-light, sans-serif", // Use light font for h4
    },
    body1: {
      fontFamily: "english-regular, sans-serif", // Regular font for body text
    },
    body2: {
      fontFamily: "english-thin, sans-serif", // Thin font for secondary body text
    },
    button: {
      fontFamily: "english-extra-bold, sans-serif", // Extra-bold for buttons
    },
  },
});
const arabicTheme = createTheme({
  typography: {
    fontFamily: "arabic, sans-serif", // Apply the Arabic font across all text
    h1: {
      fontFamily: "arabic, sans-serif", // Arabic font for h1 headers
    },
    // Map other variants if needed
  },
});

const App = () => {
  const location = useLocation();
  const isLoggedIn = !!localStorage.getItem("token");
  const isGuest = !!localStorage.getItem("guest");

  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem("language") || "ar"
  );

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage && storedLanguage !== currentLanguage) {
      setCurrentLanguage(storedLanguage);
    }
  }, [currentLanguage]);

  useEffect(() => {
    // Apply appropriate font dynamically based on language
    if (currentLanguage === "ar") {
      document.body.style.fontFamily = "'arabic', sans-serif"; // Arabic font
    } else {
      document.body.style.fontFamily = "'english-regular', sans-serif"; // English font
    }
  }, [currentLanguage]);

  const selectedTheme = currentLanguage === "ar" ? arabicTheme : theme;

  useEffect(() => {
    if (!isLoggedIn && !isGuest && location.pathname !== "/introduction") {
      // Redirect to Introduction page if the user is not logged in or a guest
      window.location.href = "/introduction";
    }
  }, [isLoggedIn, isGuest, location.pathname]);

  const bottomNavBarPaths = [
    "/",
    "/explore",
    "/explore-city",
    "/rating",
    "/rating-review",
    "/orders/previous",
    "/orders/upcoming",
    "/logout",
  ];

  const shouldShowBottomNavBar = bottomNavBarPaths.includes(location.pathname);

  return (
    <>
      <ThemeProvider theme={selectedTheme}>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/introduction" element={<Introduction />} />

          <Route path="/market" element={<HomePage />} />
          <Route index path="/explore" element={<ExplorePage />} />

          <Route path="/activities" element={<AllActivitiesPage />} />
          <Route
            path="/activities/:activityId"
            element={<SingleActivityPage />}
          />

          <Route path="/explore-city" element={<ExploreCityPage />} />
          <Route path="/explore/:activityId" element={<SingleActivityPage />} />
          <Route
            path="/special-offer/:activityId"
            element={<SingleActivityPage />}
          />
          <Route
            path="/orders/previous/:activityId/:orderId"
            element={<SingleActivityReOrderPage />}
          />
          <Route
            path="/orders/upcoming/:activityId/:orderId"
            element={<SingleOrderCancelationPage />}
          />
          <Route path="/:activityId/rating" element={<ReviewInterfacePage />} />
          <Route path="/rating-review" element={<OrderReviewPage />} />
          <Route path="/order-details" element={<OrderDetailPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/forget-password-enter-your-email"
            element={<ForgetPasswordEmailPage />}
          />
          <Route
            path="/forget-password-otp"
            element={<ForgetPasswordOtpPage />}
          />
          <Route path="/favourites" element={<MyFavouritePage />} />
          <Route
            path="/favourites/:activityId"
            element={<SingleActivityPage />}
          />
          <Route path="/my-account" element={<MyAccountPage />} />
          <Route path="/logout" element={<ProfilePage />} />
          <Route path="/register" element={<RegistrationPage />} />
          <Route path="/events" element={<EventsPage />} />
          <Route path="/orders" element={<OrdersPage />}>
            <Route index element={<Navigate to="upcoming" replace />} />
            <Route path="previous" element={<OrdersPrevious />} />
            <Route path="upcoming" element={<OrdersUpcoming />} />
          </Route>
          <Route path="/support" element={<SupportPage />} />
          <Route path="/verify-otp" element={<VerifyOtpPage />} />
          <Route path="/set-password" element={<SetPassword />} />
          <Route
            path="/:activityId/booking/booking-information"
            element={<BookingInformation />}
          />
          <Route
            path="/:activityId/booking/registration"
            element={<BookingRegistrationPage />}
          />
          <Route
            path="/:activityId/booking/payment"
            element={<BookingPaymentPage />}
          />
          <Route path="/qr-code" element={<QrCodePage />} />
          <Route path="/chat" element={<ChattingPage />} />
          <Route path="/notifications" element={<NotificationsPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </ThemeProvider>
      {shouldShowBottomNavBar && <BottomNavBar />}
    </>
  );
};

const AppWrapper = () => {
  return (
    <LanguageProvider>
      <Router>
        <App />
      </Router>
    </LanguageProvider>
  );
};

export default AppWrapper;
