import React from "react";
import classes from "./Layout.module.css";
import Header from "./Header";


function Layout({ children }) {
  return (
    <section className={classes.section}>
      <Header />
      {children}
     
    </section>
  );
}

export default Layout;
