import React from "react";

import classes from "./LandingPage.module.css";
import AboutCover from "../assets/img/about.png";

import ClickIcon from "../assets/icons/click-icon.png";
import AiIcon from "../assets/icons/Ai-icon.png";
import TimeIcon from "../assets/icons/Time-icon.png";
import LocationIcon from "../assets/icons/location-icon.png";
import CardIcon from "../assets/icons/card-icon.png";
import JabalAlFil from "../assets/img/Jabal Al-Fil.png";
import ImgOne from "../assets/img/ImgOne.png";
import ImgTwo from "../assets/img/ImgTwo.png";
import ImgThree from "../assets/img/ImgThree.png";
import ImgFour from "../assets/img/ImgFour.png";
import ImgFive from "../assets/img/ImgFive.png";
import ImgSix from "../assets/img/كــن-جزءََا-مـن-عائلة2.png";
import Layout from "../layout/Layout";

function About() {
  return (
    <Layout>
      <section className={classes.section}>
        <div>
          <img src={AboutCover} alt="" className={classes.cover} />
          <div className={classes.features}>
            <h2>ليش يلا فلة</h2>
            <div className={classes.featuresContainer}>
              <div className={classes.feature}>
                <img src={ClickIcon} alt="" />
                <p> وصول سريع لاجمل الوجهات السياحة</p>
              </div>

              <div className={classes.feature}>
                <img src={LocationIcon} alt="" />
                <p>كل شيئ فى مكان واحد</p>
              </div>

              <div className={classes.feature}>
                <img src={AiIcon} alt="" />
                <p>تقنيات سياحة</p>
                <p> بالذكاء الصناعي</p>
              </div>

              <div className={classes.feature}>
                <img src={TimeIcon} alt="" />
                <p>حجز سريع </p>
                <p>بضغطة زر</p>
              </div>

              <div className={classes.feature}>
                <img src={CardIcon} alt="" />
                <p>دفع الكتروني</p>
              </div>
            </div>
          </div>

          <section className={classes.about}>
            <h2>كيف بدأنا يلا فلة </h2>

            <div className={classes.aboutContainer}>
              <div className={classes.content}>
                <p>
                  اسمع يا صاحبي، تخيل نفسك في مدينة كأنها لوحة فنية، وكل اللي
                  تبغاه تجربة واحدة تخليك تقول “واو” وتخزنها في ذاكرتك للأبد.
                  هذا بالضبط اللي صار معاي لما رحت رحلة عمل لأبها في الصيف،
                  واحدة من أجمل وأروع المدن عندنا بالسعودية.
                </p>

                <p>
                  كنت متحمس مرّة أجرب السقوط المظلي، نشاط دايم أسمع عنه وأتخيله،
                  وقلت لازم أعيشه بنفسي. قعدت أبحث يمين ويسار، أدور على مزودي
                  الخدمة. دخلت المواقع، قلبت السوشيال ميديا، وحتى قوقل ماب ما
                  سلم مني. بس المشكلة كانت وحدة: الوقت ضيق، وما لقيت معلومات
                  واضحة ولا طريقة حجز سهلة.
                </p>

                <p>
                  ويا دوب خلصت اليومين، ورجعت من أبها بدون ما أحقق الحلم اللي
                  كان في بالي. وقتها جاتني تساؤلات غريبة:
                </p>

                <p>
                  • ليه ما يكون فيه مكان واحد يجمّع كل هالتجارب والخدمات؟ • ليه
                  ما أقدر بسهولة أعرف الأنسب لي ولوقتي وميزانيتي؟
                </p>

                <p>
                  ومن هنا، طلعت فكرة يلا فلة! منصة تجمع بين السياح ومزودي
                  الخدمات بكل بساطة، وتستخدم الذكاء الاصطناعي عشان تسهّل حياتك
                  وتخلي كل لحظة فلة.
                </p>
              </div>
              <img src={JabalAlFil} alt="" />
            </div>
          </section>

          <section className={classes.owner}>
            <h2>مالك شركة سياحية او تقدم خدمات سياحية؟</h2>
            <img src={ImgSix} alt="" />
          </section>

          <section className={classes.WhyYallaFalla}>
            <h2>ليش لازم تكون جزء من يلا فلة ؟</h2>
            <div className={classes.YallaFallaOffers}>
              <div className={classes.offer}>
                <img src={ImgOne} alt="" />
                <p>زيادة الانتشار</p>
                <p>والمبيعات</p>
              </div>

              <div className={classes.offer}>
                <img src={ImgTwo} alt="" />
                <p>وصول عالي بفضل</p>
                <p>الذكاء الصناعي</p>
              </div>

              <div className={classes.offer}>
                <img src={ImgThree} alt="" />
                <p>ادارة سهلة لخدماتك</p>
                <p>السياحة</p>
              </div>

              <div className={classes.offer}>
                <img src={ImgFour} alt="" />
                <p>امان عالي وضمان</p>
                <p>لارباحك</p>
              </div>

              <div className={classes.offer}>
                <img src={ImgFive} alt="" />
                <p>دعم فني على</p>
                <p>مدار الساءة</p>
              </div>
            </div>
          </section>
        </div>
      </section>
    </Layout>
  );
}

export default About;
